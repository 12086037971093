import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наш вибір вінілу та досвід у SkisaPro
			</title>
			<meta name={"description"} content={"SkisaPro пропонує набір послуг, покликаних занурити вас у світ вінілових платівок, поєднуючи тактильну красу вінілу зі спільною радістю музичних відкриттів."} />
			<meta property={"og:title"} content={"Наші послуги | Наш вибір вінілу та досвід у SkisaPro"} />
			<meta property={"og:description"} content={"SkisaPro пропонує набір послуг, покликаних занурити вас у світ вінілових платівок, поєднуючи тактильну красу вінілу зі спільною радістю музичних відкриттів."} />
			<meta property={"og:image"} content={"https://ua.skisapro.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.skisapro.com/img/old.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.skisapro.com/img/old.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.skisapro.com/img/old.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.skisapro.com/img/old.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.skisapro.com/img/old.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.skisapro.com/img/old.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://ua.skisapro.com/img/3.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Наші послуги{" "}
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							SkisaPro пропонує набір послуг, покликаних занурити вас у світ вінілових платівок, поєднуючи тактильну красу вінілу зі спільною радістю музичних відкриттів. Від ексклюзивних сеансів прослуховування до персоналізованих рекомендацій, наша місія - збагатити вашу музичну подорож.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Image
						src="https://ua.skisapro.com/img/4.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Основні послуги
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Безліч жанрів: Дослідіть нашу величезну колекцію, що охоплює рок, джаз, класику, інді та багато іншого. Кожен жанр представлений як класичними альбомами, так і сучасними шедеврами, що гарантує, що кожен візит відкриє для вас нові звуки.
							<br />
							<br />
							- Знахідки лімітованих видань: Пориньте в нашу добірку рідкісних та лімітованих видань, що ідеально підходять для колекціонерів та тих, хто шукає унікальну частину музичної історії.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Персональні сеанси прослуховування: Наші найсучасніші станції прослуховування дозволяють повністю зануритися в атмосферу вінілу, оцінивши теплоту і глибину аналогового звучання ще до покупки.
							<br />
							<br />
							- Спільні заходи з прослуховування: Приєднуйтесь до нас на вечірки з нагоди виходу нових альбомів, презентації виконавців та вечори дослідження жанрів, де меломани збираються разом, щоб відсвяткувати нові звуки та старі улюблені композиції.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Ексклюзивні переваги членства
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Безліч жанрів: Дослідіть нашу величезну колекцію, що охоплює рок, джаз, класику, інді та багато іншого. Кожен жанр представлений як класичними альбомами, так і сучасними шедеврами, що гарантує, що кожен візит відкриє для вас нові звуки.
							<br />
							<br />
							- Знахідки лімітованих видань: Пориньте в нашу добірку рідкісних та лімітованих видань, що ідеально підходять для колекціонерів та тих, хто шукає унікальну частину музичної історії.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							- Релізи лише для членів клубу: Отримайте доступ до ексклюзивних видань та лімітованих тиражів, доступних лише для членів SkisaPro.
							<br />
							<br />
							- Пріоритетний доступ до подій: Члени клубу отримують запрошення на спеціальні заходи заздалегідь, що гарантує вам найкращі місця в залі на вечірках прослуховування та зустрічах з артистами.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Image
						src="https://ua.skisapro.com/img/5.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://ua.skisapro.com/img/6.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Як з нами зв'язатися
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Для отримання додаткової інформації про наші послуги, колекції або події, ми запрошуємо вас зв'язатися з нами. Незалежно від того, чи є ви досвідченим колекціонером, чи новачком у світі вінілу, SkisaPro - це місце, де ви знайдете все, що пов'язано з музикою.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});